import _ from 'lodash'
import { EVENT_TYPES_MAP } from './constants'
import { getFullId, isDisplayedOnly } from '@wix/thunderbolt-commons'
import { ComponentSdksManager, EventHandlers, Model } from './types'
import { IControllerEvents } from './ControllerEvents'
import { PlatformLogger } from '@wix/thunderbolt-symbols'
import { WixSelector } from './wixSelector'

export type StaticEventsManager = {
	setStaticEventsCallbacks: (eventHandlers: EventHandlers) => void
}

export function createPromise<T = any>(): { resolver: (resolvedData: T) => void; promise: Promise<T> } {
	let resolver = () => {}
	const promise = new Promise<T>((_resolver) => (resolver = _resolver))
	return {
		resolver,
		promise
	}
}

export function CreateStaticEventsManager({
	models,
	controllerEventsFactory,
	wixSelector,
	logger,
	componentSdksManager
}: {
	models: Model
	controllerEventsFactory: IControllerEvents
	wixSelector: WixSelector
	logger: PlatformLogger
	componentSdksManager: ComponentSdksManager
}): StaticEventsManager {
	const { structureModel, platformModel } = models
	const { resolver: staticEventCallbacksResolver, promise: staticEventCallbacksPromise } = createPromise<EventHandlers>()
	function reportStaticEventsError(errorMessage: string, extra: any) {
		logger.captureError(new Error(`WixCode Static Events Error: ${errorMessage}`), {
			tags: {
				staticEvents: true
			},
			extra
		})
	}
	function getCompIdFromEventOrModels(compIdFromEvent: string) {
		let compId = compIdFromEvent
		if (isDisplayedOnly(compId)) {
			compId = getFullId(compId)
		}
		if (structureModel[compId!]) {
			return compId
		} else {
			const connection = _.head(platformModel.connections.wixCode[compId!])
			return connection?.compId
		}
	}

	function createDynamicEvent({ compId, viewerEvent, handler }: { compId: string; viewerEvent: string; handler: Function }) {
		const role = models.getRoleForCompId(compId, 'wixCode') as string
		const compType = models.getCompType(compId) as string
		const sdkInstance = wixSelector.getInstance({ controllerCompId: 'wixCode', compId, role, compType }) as any
		if (!_.isFunction(sdkInstance[viewerEvent])) {
			reportStaticEventsError('viewerEvent does not exists in sdkInstance', {
				compId,
				viewerEvent,
				sdkInstanceKeys: Object.keys(sdkInstance)
			})
			return
		}
		sdkInstance[viewerEvent](handler)
	}

	function registerStaticEvents(callbacks: EventHandlers) {
		platformModel.staticEvents.forEach(({ compId: eventCompId, eventType, callbackId: fnName }) => {
			const viewerEvent = EVENT_TYPES_MAP[eventType]
			const compId = getCompIdFromEventOrModels(eventCompId)
			if (!compId) {
				reportStaticEventsError('could not find component in the given static event behavior data', {
					eventCompId,
					eventType,
					fnName
				})
				return
			}
			const handler = callbacks[fnName]
			if (!handler) {
				reportStaticEventsError('could not find callback in the given static event callbackId', {
					eventCompId,
					eventType,
					fnName,
					callbacks: Object.keys(callbacks)
				})
				return
			}
			if (viewerEvent) {
				// if the event is in the list of viewer events (onClick, onMouseEnter, etc..) we register it as a dynamic event ($w.onMouseEnter())
				createDynamicEvent({ compId, viewerEvent, handler })
			} else if (models.platformModel.orderedControllers.includes(compId)) {
				// might be a custom events of the controller, for example "onDatasetReady" - then we register it as a controller event.
				controllerEventsFactory.createScopedControllerEvents(compId).on(eventType, handler)
			} else {
				reportStaticEventsError('eventType is not found in viewerEvents', {
					eventType,
					compId,
					fnName,
					EVENT_TYPES_MAP
				})
			}
		})
	}

	Promise.all([staticEventCallbacksPromise, componentSdksManager.waitForSdksToLoad()]).then(([staticEventCallbacks]) => registerStaticEvents(staticEventCallbacks))

	return {
		setStaticEventsCallbacks(callbacks) {
			staticEventCallbacksResolver(callbacks)
		}
	}
}
