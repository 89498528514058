import { PlatformEnvData, PlatformUtils } from '@wix/thunderbolt-symbols'
import { namespace, PrivateWixCodeSdkWixCodeApi } from '..'

/**
 * This is SDK Factory.
 * Expose your Corvid API
 */
export function PrivateSdkFactory(
	_: any,
	__: any,
	{ biUtils }: PlatformUtils,
	{ bi: biData }: PlatformEnvData
): { [namespace]: PrivateWixCodeSdkWixCodeApi } {
	return {
		[namespace]: {
			biLoggerFactory: (endpoint: string, src: number, defaults: Record<string, any>) => {
				if (!endpoint) {
					throw new Error('First argument "endpoint" is required')
				}

				if (!src) {
					throw new Error('Second argument "src" is required')
				}

				return biUtils
					.createBaseBiLoggerFactory(biData)
					.updateDefaults({ ...defaults, src })
					.logger({ endpoint })
			},
		},
	}
}
