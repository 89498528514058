import { BootstrapData } from '../types'
import { EventHandlers } from './types'
import { AppSpecData } from '@wix/thunderbolt-symbols'
import { MasterPageId } from './constants'
import { StaticEventsManager } from './staticEventsManager'

export interface WixCodeViewerAppUtils {
	createWixCodeAppData(
		appData: AppSpecData
	): {
		userCodeMap: Array<{
			url: string
			displayName: string
			id: string
			scriptName: string
		}>
	}
	setStaticEventHandlers(eventHandlers: EventHandlers): void
}

export default function({ bootstrapData, staticEventsManager }: { bootstrapData: BootstrapData; staticEventsManager: StaticEventsManager }) {
	const { wixCodePageIds } = bootstrapData.wixCodeBootstrapData

	return {
		createWixCodeAppData() {
			return {
				userCodeMap: [MasterPageId, bootstrapData.currentPageId!]
					.filter((pageId) => wixCodePageIds[pageId])
					.map((pageId: string) => ({
						url: wixCodePageIds[pageId],
						displayName: pageId === MasterPageId ? 'site' : `${bootstrapData.platformEnvData.site.pageIdToTitle[pageId]} page`,
						id: pageId,
						scriptName: `${pageId}.js`
					}))
			}
		},
		setStaticEventHandlers: async (eventHandlers: EventHandlers) => {
			staticEventsManager.setStaticEventsCallbacks(eventHandlers)
		}
	}
}
